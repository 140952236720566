// import axios from "axios";

const version = "v1";
const basePath = "/core/identities/";

export function getUserPermission(merchantId, userId, querystring) {
  const appId = "admin";
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/app/${appId}/admin-users/${userId}/permissions`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function generateStoreManagerInviteUrl(merchantId, storeId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/iam/stores/${storeId}/issueInvitation-url`,
    data: data,
  };

  return window.qcsso.request(config);
}

export function generateCampaignManagerInviteUrl(merchantId, campaignId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/iam/campaigns/${campaignId}/issueInvitation-url`,
    data: data,
  };

  return window.qcsso.request(config);
}
