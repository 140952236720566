export default {
  authServices: {
    points: "Admin:PointManage",
    stores: "Admin:StoreManage",
    donate: "Admin:DonateManage",
    campaigns: "Admin:CampaignManage",
    articles: "Admin:ArticleManage",
    account: "Admin:AccountManage",
    accounting: "Admin:AccountingManage",
    marketing: "Admin:MarketingManage",
  },
  missionVisibiltyOptions: [
    {
      name: "公開活動",
      value: "public",
    },
    // {
    //   name: "非公開活動",
    //   value: "private",
    // },
    {
      name: "隱藏活動",
      value: "hidden",
    },
  ],
  publisherTypes: [
    {
      name: "市政服務",
      value: "municipal",
    },
    {
      name: "專屬任務",
      value: "exclusive",
    },
  ],
  videoRepetitionModes: [
    {
      name: "每天一次",
      value: "daily",
    },
    {
      name: "不限制",
      value: "freely",
    },
    {
      name: "不可重複",
      value: "forbidden",
    },
  ],
  questionTypes: [
    {
      value: "single-choice",
      text: "單選",
      isQASupported: true,
      isQuestionnaireSuppported: true,
    },
    {
      value: "multi-choice",
      text: "多選",
      isQASupported: false,
      isQuestionnaireSuppported: true,
    },
    {
      value: "short-answer",
      text: "簡答",
      isQASupported: false,
      isQuestionnaireSuppported: false,
    },
  ],
  itemsPerPage: [
    {
      value: 5,
      name: "5",
    },
    {
      value: 10,
      name: "10",
    },
    {
      value: 20,
      name: "20",
    },
    {
      value: 50,
      name: "50",
    },
    {
      value: 100,
      name: "100",
    },
    {
      value: -1,
      name: "ALL",
    },
  ],
  entryUrls: {
    questions: "/questions",
    "stamp-collection": "/scanner",
    minigame: "/turntable",
    "slot-machine": "/slot-machine",
  },
  cardStatus: [
    {
      name: "草稿",
      code: "00",
    },
    {
      name: "申請待審",
      code: "10",
    },
    {
      name: "線上審核資料",
      code: "11",
    },
    {
      name: "待取繳費單號",
      code: "12",
    },
    {
      name: "待繳費用",
      code: "14",
    },
    {
      name: "線卡印卡資料",
      code: "16",
    },
    {
      name: "等待列印",
      code: "18",
    },
    {
      name: "通過待印",
      code: "20",
    },
    {
      name: "印卡送出",
      code: "21",
    },
    {
      name: "等待印卡回應",
      code: "25",
    },
    {
      name: "已印待交",
      code: "30",
    },
    {
      name: "已寄交",
      code: "40",
    },
    {
      name: "等待靠卡",
      code: "50",
    },
    {
      name: "印錯重印",
      code: "55",
    },
    {
      name: "開卡使用中",
      code: "60",
    },
    {
      name: "暫時停用",
      code: "70",
    },
    {
      name: "停用",
      code: "80",
    },
    {
      name: "測試資料註銷",
      code: "81",
    },
    {
      name: "小工具註銷",
      code: "82",
    },
    {
      name: "停用舊的匯入資料",
      code: "83",
    },
    {
      name: "作廢",
      code: "90",
    },
    {
      name: "因死亡導致的停卡",
      code: "95",
    },
    {
      name: "因遷出導致的停卡",
      code: "96",
    },
    {
      name: "因統號變更導致的停卡",
      code: "97",
    },
    {
      name: "因身份資格變更導致的停卡",
      code: "99",
    },
  ],
  cardTypes: [
    {
      name: "一般卡",
      code: "01",
    },
    {
      name: "行動市民學生卡",
      code: "10",
    },
    {
      name: "原民卡",
      code: "12",
    },
    {
      name: "信用聯名卡",
      code: "14",
    },
    {
      name: "志工一般卡",
      code: "16",
    },
    {
      name: "員工卡",
      code: "02",
    },
    {
      name: "兒童優待卡",
      code: "20",
    },
    {
      name: "一銀聯名卡",
      code: "22",
    },
    {
      name: "外籍工作者",
      code: "24",
    },
    {
      name: "一銀認同卡",
      code: "26",
    },
    {
      name: "外籍人士敬老卡",
      code: "28",
    },
    {
      name: "學生普通卡",
      code: "03",
    },
    {
      name: "學生優待卡",
      code: "04",
    },
    {
      name: "敬老卡",
      code: "05",
    },
    {
      name: "愛心卡",
      code: "06",
    },
    {
      name: "愛陪卡",
      code: "07",
    },
    {
      name: "行動市民卡一般卡",
      code: "08",
    },
  ],
};
