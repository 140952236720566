import Vue from "vue";
import Vuex from "vuex";
import { checkPermission } from "src/util/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    ssoUser: null,
    userPermissions: null,
    pointSettings: null,
    permissionScope: null,
  },
  mutations: {
    setSSOUser(state, user) {
      state.ssoUser = user;
    },
    setIsLoggedIn(state, data) {
      state.isLoggedIn = data;
    },
    setPointSettings(state, data) {
      state.pointSettings = data;
    },
    setUserPermission(state, data) {
      state.userPermissions = data;

      if (data && data.length) {
        // 可以存取機關
        let allowOrg = checkPermission(
          data,
          "Admin:AccountManage",
          "Organizations",
          false
        );
        // 可以存取單位
        let allowDept = checkPermission(
          data,
          "Admin:AccountManage",
          "Departments",
          false
        );
        state.permissionScope = {
          organization: allowOrg,
          department: allowDept,
        };
      } else {
        state.permissionScope = {
          organization: false,
          department: false,
        };
      }
    },
  },
  actions: {},
  modules: {},
});
