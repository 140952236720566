export function generateArrayOfYears(range) {
  var max = new Date().getFullYear();
  var min = 2022;
  var years = [];
  if (range) {
    max = max + range;
  } else {
    max += 10;
  }

  for (var i = max; i >= min; i--) {
    years.unshift(i.toString());
  }
  return years;
}

export function generateArrayOfDays() {
  var max = 31;
  var min = 1;
  var days = [];

  for (var i = max; i >= min; i--) {
    days.unshift(i.toString().padStart(2, "0"));
  }
  return days;
}
export function generateArrayOfMonths() {
  const months = [
    {
      value: "01",
      name: "1月 January",
    },
    {
      value: "02",
      name: "2月 February",
    },
    {
      value: "03",
      name: "3月 March",
    },
    {
      value: "04",
      name: "4月 April",
    },
    {
      value: "05",
      name: "5月 May",
    },
    {
      value: "06",
      name: "6月 June",
    },
    {
      value: "07",
      name: "7月 July",
    },
    {
      value: "08",
      name: "8月 August",
    },
    {
      value: "09",
      name: "9月 September",
    },
    {
      value: "10",
      name: "10月 Octomber",
    },
    {
      value: "11",
      name: "11月 November",
    },
    {
      value: "12",
      name: "12月 December",
    },
  ];
  return months;
}

export function formatNumber(num, fraction) {
  let digits = fraction ? fraction : 0;
  if (!num) num = 0;
  var p = num.toFixed(digits).split(".");
  if (digits) {
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i) {
          return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
        }, "") +
      "." +
      p[1]
    );
  } else {
    return p[0]
      .split("")
      .reverse()
      .reduce(function (acc, num, i) {
        return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
      }, "");
  }
}

export function generateArrayOfHours() {
  var max = 23;
  var min = 0;
  var hours = [];

  for (var i = max; i >= min; i--) {
    hours.unshift({
      value: i,
      name: i.toString().padStart(2, "0"),
    });
  }
  return hours;
}

export function generateArrayOfMinutes() {
  var max = 59;
  var min = 0;
  var minutes = [];

  for (var i = max; i >= min; i--) {
    minutes.unshift({
      value: i,
      name: i.toString().padStart(2, "0"),
    });
  }
  return minutes;
}

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isURL(str) {
  const regexp =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
  return str && str.length ? regexp.test(str) : true;
}

export function checkPermission(permissions, service, keyword, equal) {
  // [
  //   {
  //     "service": "point-manage",
  //     "actions": "*",
  //     "effect": "Allow"
  //   },
  //   {
  //     "service": "account-manage",
  //     "actions": ["GetOrganization","PutOrganization","GetDepartment","PutDepartment"],
  //     "effect": "Allow"
  //   }
  // ]
  // console.log(`checkPemission: ${service}, ${keyword}, ${equal}`);
  let actions = null;
  if (!equal) {
    switch (keyword) {
      case "AdminUsers":
      case "Organizations":
      case "Departments":
      case "StoreCategories":
      case "PointDashboard":
      case "PointSettings":
      case "PointPeriods":
      case "ArticleCategories":
      case "Premembers":
      case "DonateInvoices":
      case "Apps":
      case "DataQuery":
        actions = [`View${keyword}`, `Edit${keyword}`];
        break;
      case "Members":
        actions = [`View${keyword}`, `Edit${keyword}`, "SendMessage"];
        break;
      case "Articles":
      case "Banners":
      case "AnnualBudgets":
      case "DepartmentBudgets":
      case "CampaignBudgets":
      case "Withdrawals":
        actions = [`View${keyword}`, `Edit${keyword}`, `Audit${keyword}`];
        break;
      case "Campaigns":
        actions = [
          `View${keyword}`,
          `Edit${keyword}`,
          `Audit${keyword}`,
          `Edit${keyword}Managers`,
          `EditAdvanced${keyword}`,
        ];
        break;
      case "Stores":
        actions = [
          `View${keyword}`,
          `Edit${keyword}`,
          `Audit${keyword}`,
          `Edit${keyword}Managers`,
        ];
        break;
      case "PointPeriods":
        actions = ["ViewPointPeriods", "EditPointPeriods", "ClosePointPeriods"];
        break;
      default:
        actions = [];
        break;
    }
  } else {
    actions = [keyword];
    // console.log(`checkPemission: ${service}, ${keyword}, ${equal}`);
  }
  let allow = false;
  let matchedService = permissions.filter((p) => p.service === service);
  if (matchedService.length) {
    let matchedActions = matchedService[0].actions;
    if (matchedActions === "*") {
      // * 代表最大許可
      allow = true;
      return allow;
    } else if (matchedActions.length && !keyword) {
      // 檢查第一層entry，只要matchedActions有值就可以看到
      allow = true;
      return allow;
    } else {
      // 檢查第二層entry，有符合keyword的任一action，即可以看到
      allow = actions.some((a) => matchedActions.includes(a));
      // console.log("found :" + allow, actions);
      return allow;
    }
  }
  return allow;
}

export function isBanFormat(invoice) {
  let patt = new RegExp(/^\d{8}$/);
  if (invoice && patt.test(invoice)) {
    let sum = 0;
    let result = false;
    // let timeNow = moment().tz('Asia/Taipei').format('YYYY-MM-DD');
    // let newVersionDate = '2023-04-01';
    // let factor = timeNow >= newVersionDate ? 5 : 10;
    let factor = 10;
    if (invoice[6] == 7) {
      sum =
        Number(invoice[0]) +
        Number(invoice[2]) +
        Number(invoice[4]) +
        Number(invoice[7]) +
        Math.floor((2 * Number(invoice[1])) / 10) +
        Math.floor((2 * Number(invoice[3])) / 10) +
        Math.floor((2 * Number(invoice[5])) / 10) +
        (((2 * Number(invoice[1])) % 10) +
          ((2 * Number(invoice[3])) % 10) +
          ((2 * Number(invoice[5])) % 10));
      let sum2 = sum + 1;
      result = sum % factor == 0 || sum2 % factor == 0;
    } else {
      sum =
        Number(invoice[0]) +
        Number(invoice[2]) +
        Number(invoice[4]) +
        Number(invoice[7]) +
        Math.floor((2 * Number(invoice[1])) / 10) +
        Math.floor((2 * Number(invoice[3])) / 10) +
        Math.floor((2 * Number(invoice[5])) / 10) +
        Math.floor((4 * Number(invoice[6])) / 10) +
        (((2 * Number(invoice[1])) % 10) +
          ((2 * Number(invoice[3])) % 10) +
          ((2 * Number(invoice[5])) % 10) +
          ((4 * Number(invoice[6])) % 10));
      result = sum % factor == 0;
    }
    return result;
  } else {
    return false;
  }
}
export function isIdFormat(value) {
  const idStr = value.trim().toUpperCase();
  // 依照字母的編號排列，存入陣列備用。
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "X",
    "Y",
    "W",
    "Z",
    "I",
    "O",
  ];
  // 儲存各個乘數
  const multiply = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
  const nums = new Array(2);
  let firstChar = null;
  let firstNum = null;
  // const lastNum = null;
  let total = 0;
  // 撰寫「正規表達式」。第一個字為英文字母，
  // 第二個字為1或2，後面跟著8個數字，不分大小寫。
  const regExpID = /^[a-zA-Z](1|2)\d{8}$/i;

  // 居留證號
  const regExpID2 = /^[A-Z]{1}[A-D]{1}[0-9]{8}$/i;
  const regExpID3 = /^[A-Z]{1}(8|9){1}[0-9]{8}$/i;

  // 原身分證英文字應轉換為10~33，這裡直接作個位數*9+10
  // let pidIDInt = [1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30 ];

  // 原居留證第一碼英文字應轉換為10~33，十位數*1，個位數*9，這裡直接作[(十位數*1) mod 10] + [(個位數*9) mod 10]
  const pidCharArray = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const pidResidentFirstInt = [
    1, 10, 9, 8, 7, 6, 5, 4, 9, 3, 2, 2, 11, 10, 8, 9, 8, 7, 6, 5, 4, 3, 11, 3,
    12, 10,
  ];

  // 原居留證第二碼英文字應轉換為10~33，並僅取個位數*8，這裡直接取[(個位數*8) mod 10]
  const pidResidentSecondInt = [
    0, 8, 6, 4, 2, 0, 8, 6, 2, 4, 2, 0, 8, 6, 0, 4, 2, 0, 8, 6, 4, 2, 6, 0, 8,
    4,
  ];

  let verifyNum = 0;

  if (idStr.search(regExpID) > -1) {
    // 檢驗身份證號
    firstChar = idStr.charAt(0).toUpperCase();
    // // 找出第一個字母對應的數字，並轉換成兩位數數字。
    for (let i = 0; i < 26; i++) {
      if (firstChar === letters[i]) {
        firstNum = i + 10;
        nums[0] = Math.floor(firstNum / 10);
        nums[1] = firstNum - nums[0] * 10;
        break;
      }
    }
    // 執行加總計算
    for (let i = 0; i < multiply.length; i++) {
      if (i < 2) {
        total += nums[i] * multiply[i];
      } else {
        total += parseInt(idStr.charAt(i - 1)) * multiply[i];
      }
    }
    if (total % 10 === 0) {
      return true;
    }
  }

  verifyNum = 0;
  if (idStr.search(regExpID2) > -1) {
    /* 檢查統一證(居留證)編號 (2031/1/1停用) */
    // 第一碼
    let character = idStr.charAt(0);
    let index = pidCharArray.findIndex((c) => {
      return c === character;
    });
    verifyNum += pidResidentFirstInt[index];
    // 第二碼
    character = idStr.charAt(1);
    index = pidCharArray.findIndex((c) => {
      return c === character;
    });
    verifyNum += pidResidentSecondInt[index];
    // 第三~八碼
    for (let i = 2, j = 7; i < 9; i++, j--) {
      verifyNum += parseInt(idStr.charAt(i)) * j;
    }
    // 檢查碼
    verifyNum += parseInt(idStr.charAt(9));
    if (verifyNum % 10 === 0) {
      return true;
    }
  }

  verifyNum = 0;
  if (idStr.search(regExpID3) > -1) {
    /* 檢查統一證(居留證)編號 (2021/1/2實施) */

    // 找出第一個字母對應的數字，並轉換成兩位數數字。
    const character = idStr.charAt(0);
    const index = pidCharArray.findIndex((c) => {
      return c === character;
    });
    verifyNum += pidResidentFirstInt[index];
    // 第二~八碼
    for (let i = 1, j = 8; i < 9; i++, j--) {
      verifyNum += parseInt(idStr.charAt(i)) * j;
    }
    // 檢查碼
    verifyNum += parseInt(idStr.charAt(9));
    if (verifyNum % 10 === 0) {
      return true;
    }
  }

  return false;
}

export function isInteger(v) {
  const regexp = /^[0-9]*$/;
  return v && v.length ? regexp.test(v) : true;
}

export function isPositiveInteger(v) {
  const regexp = /^[1-9][0-9]*$/;
  return v && v.length ? regexp.test(v) : true;
}

export function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function censorName(name) {
  if (!name) {
    return "";
  }
  const ascii = /^[ -~]+$/;
  let ret = "";
  if (ascii.test(name.substr(0, 2))) {
    //If there're no non-ascii characters in the name...
    let maskStart = Math.ceil(name.length * 0.4);
    ret = name.substr(0, maskStart);
    for (let i = maskStart; i < name.length - 1; i++) ret += "*";
    ret += name.charAt(name.length - 1);
  } else {
    //Otherwise...
    if (name.length == 1) ret = `〇`;
    else if (name.length == 2) ret = `${name.charAt(0)}〇`;
    else {
      ret = name.substr(0, 1);
      for (let i = 1; i < name.length - 1; i++) ret += "〇";
      ret += name.charAt(name.length - 1);
    }
  }
  return ret;
}
