<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
              src="@/assets/img/logo-ct-white.png"
              class="navbar-brand-img ms-3"
              width="32"
              v-if="sidebarTheme == 'dark'"
            >
            </v-img>
            <v-img
              src="@/assets/img/logo-ct.png"
              class="navbar-brand-img ms-3"
              width="32"
              v-else
            >
            </v-img>
            <span class="ms-2 font-weight-bold text-sm">{{
              $t('sidebar["Management Portal"]')
            }}</span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr
      class="horizontal mb-0"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense v-if="isLoggedIn && ssoUser">
      <v-list-group
        :ripple="false"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-avatar size="30" class="my-3 ms-2">
            <img
              v-if="
                ssoUser &&
                ssoUser.user &&
                ssoUser.user._json &&
                ssoUser.user._json.picture
              "
              :src="ssoUser.user._json.picture"
              alt="Member"
            />
            <v-icon v-else small color="white">fa-regular fa-user</v-icon>
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title class="ms-2 ps-1 font-weight-light">
              {{ ssoUser.displayName }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover py-2"
          v-for="child in userInfo"
          :key="child.title"
          :to="child.link"
          @click="child.link ? null : onClickTab($event, child.title)"
        >
          <span
            class="v-list-item-mini ms-0 font-weight-light text-center w-20"
            v-text="child.prefix"
          ></span>
          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title
              v-text="$t(`sidebar.${child.title}`)"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="$t(`sidebar.${child.title}`)"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t(`sidebar.${child2.title}`)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <hr
      class="horizontal mb-3"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-group
        :ripple="false"
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
        v-show="item.display"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10" v-if="item.material">{{
              item.action
            }}</i>
            <v-icon dense color="white" v-else>{{ item.action }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t(`sidebar.${item.title}`)"
              class="ms-1"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          :to="child.link"
          v-show="child.display"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

            <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
              <v-list-item-title
                v-text="$t(`sidebar.${child.title}`)"
                @click="listClose($event)"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
              <v-list-group
                prepend-icon=""
                :ripple="false"
                sub-group
                no-action
                v-model="child.active"
              >
                <template v-slot:activator>
                  <v-list nav dense class="pa-0">
                    <v-list-group
                      :ripple="false"
                      append-icon="fas fa-angle-down me-auto ms-1"
                      active-class="item-active"
                      class="mb-0"
                    >
                      <template v-slot:activator class="mb-0">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            v-text="$t(`sidebar.${child.title}`)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-group>
                  </v-list>
                </template>
              </v-list-group>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list-group>

      <hr
        class="horizontal my-4"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
      />
      <v-list-item-group>
        <div v-for="(item, i) in itemsDocs" :key="i">
          <v-list-item
            link
            :to="item.link"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
            v-if="item.display && !item.external"
          >
            <v-list-item-icon class="me-2 align-center">
              <!-- <i class="material-icons-round opacity-10">{{ item.action }}</i> -->
              <i class="material-icons-round opacity-10" v-if="item.material">{{
                item.action
              }}</i>
              <v-icon dense color="white" v-else>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(`sidebar.${item.title}`)"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :href="item.link"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
            v-if="item.display && item.external"
            target="_blank"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">{{ item.action }}</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import { checkPermission } from "src/util/utils";

export default {
  name: "ddc-drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
  },
  watch: {
    permissions: {
      handler(val) {
        if (val) {
          this.initSidebar();
        }
      },
      deep: true,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    userInfo: [
      // {
      //   title: "My Profile",
      //   prefix: "MP",
      //   link: "/members/profile",
      // },
      // {
      //   title: "Settings",
      //   prefix: "S",
      //   link: "/members/settings",
      // },
      {
        title: "Logout",
        prefix: "L",
      },
    ],
    items: [],
    // items: [
    //   {
    //     material: true, // materal icon
    //     action: "groups", // icon
    //     active: false, // selected
    //     title: "Account Management",
    //     display: true,
    //     items: [
    //       {
    //         title: "User Management",
    //         prefix: "M",
    //         display: true,
    //         link: "/accounts/users",
    //       },
    //       {
    //         title: "Organization Management",
    //         prefix: "O",
    //         display: true,
    //         link: "/accounts/organizations",
    //       },
    //       {
    //         title: "Department Management",
    //         prefix: "D",
    //         display: true,
    //         link: "/accounts/departments",
    //       },
    //       {
    //         title: "Event Organizer Management",
    //         prefix: "E",
    //         display: false,
    //         link: "/accounts/organizations",
    //       },
    //     ],
    //   },
    //   {
    //     material: true, // materal icon
    //     action: "storefront", // icon
    //     active: false, // selected
    //     title: "Store Management",
    //     display: true,
    //     items: [
    //       {
    //         title: "Store Categories",
    //         prefix: "C",
    //         display: true,
    //         link: "/stores/categories",
    //       },
    //       {
    //         title: "Store List",
    //         prefix: "S",
    //         display: true,
    //         link: "/stores/list",
    //       },
    //     ],
    //   },
    //   {
    //     material: false, // materal icon
    //     action: "fa-coins", // icon
    //     active: false, // selected
    //     title: "Points",
    //     display: true,
    //     items: [
    //       {
    //         title: "Point Dashboard",
    //         prefix: "D",
    //         display: true,
    //         link: "/points/dashboard",
    //       },
    //       {
    //         title: "Point Configuration",
    //         prefix: "PC",
    //         display: true,
    //         link: "/points/point-configuration",
    //       },
    //       {
    //         title: "Period Management",
    //         prefix: "PM",
    //         display: true,
    //         link: "/points/period-management",
    //       },
    //       {
    //         title: "Annual Budget",
    //         prefix: "AB",
    //         display: true,
    //         link: "/points/annual-budget",
    //       },
    //       {
    //         title: "Department Budget",
    //         prefix: "DB",
    //         display: true,
    //         link: "/points/department-budget",
    //       },
    //       {
    //         title: "Campaign Budget",
    //         prefix: "CB",
    //         display: true,
    //         link: "/points/campaign-budget",
    //       },
    //     ],
    //   },
    //   // {
    //   //   material: true, // materal icon
    //   //   action: "redeem", // icon
    //   //   active: false, // selected
    //   //   title: "Tickets",
    //   //   display: true,
    //   //   items: [
    //   //     {
    //   //       title: "Ticket Dashboard",
    //   //       prefix: "D",
    //   //       display: true,
    //   //       link: "/tickets/dashboard",
    //   //     },
    //   //     {
    //   //       title: "Ticket Management",
    //   //       prefix: "TM",
    //   //       display: true,
    //   //       link: "/tickets/management",
    //   //     },
    //   //   ],
    //   // },
    //   {
    //     material: true, // materal icon
    //     action: "local_activity", // icon
    //     active: false, // selected
    //     title: "Campaigns",
    //     display: true,
    //     items: [
    //       {
    //         title: "Campaign List",
    //         prefix: "L",
    //         display: true,
    //         link: "/campaigns/list",
    //       },
    //     ],
    //   },
    //   {
    //     material: true, // materal icon
    //     action: "price_check", // icon
    //     active: false, // selected
    //     title: "Accounting Management",
    //     display: true,
    //     items: [
    //       {
    //         title: "Withdrawal List",
    //         prefix: "L",
    //         display: true,
    //         link: "/withdraw/list",
    //       },
    //       {
    //         title: "ACH Records",
    //         prefix: "R",
    //         display: false,
    //         link: "/withdraw/ach-records",
    //       },
    //     ],
    //   },
    //   {
    //     material: true, // materal icon
    //     action: "campaign", // icon
    //     active: false, // selected
    //     title: "Landing Page Management",
    //     display: true,
    //     items: [
    //       {
    //         title: "Article Category Management",
    //         prefix: "C",
    //         display: true,
    //         link: "/landing/article-categories",
    //       },
    //       {
    //         title: "Article Management",
    //         prefix: "A",
    //         display: true,
    //         link: "/landing/articles",
    //       },
    //       {
    //         title: "Banner Management",
    //         prefix: "B",
    //         display: true,
    //         link: "/landing/banners",
    //       },
    //     ],
    //   },
    //   // {
    //   //   material: true, // materal icon
    //   //   action: "receipt", // icon
    //   //   active: false, // selected
    //   //   title: "Accounting Management",
    //   //   display: true,
    //   //   items: null,
    //   // },
    // ],
    itemsDocs: [
      {
        material: true,
        action: "work_history",
        link: "/operation-record",
        title: "Operation Record",
        external: false,
        display: false,
      },
      // {
      //   action: "view_in_ar",
      //   link: "https://vuetifyjs.com/en/components/alerts/",
      //   title: "Components",
      //   external: true,
      // },
    ],
  }),
  created() {
    console.log("ddc drawer created:" + this.isLoggedIn);
    // console.log(this.permissions);
    if (this.permissions && this.permissions.length) {
      this.initSidebar();
    }
  },
  methods: {
    initSidebar() {
      const groupName = this.$route.meta.groupName;

      this.items = [
        {
          material: true, // materal icon
          action: "groups", // icon
          active: groupName === "Account Management", // selected
          title: "Account Management",
          display: checkPermission(
            this.permissions,
            "Admin:AccountManage",
            null
          ),
          items: [
            {
              title: "User Management",
              prefix: "U",
              display: checkPermission(
                this.permissions,
                "Admin:AccountManage",
                "AdminUsers"
              ),
              link: "/accounts/users",
            },
            {
              title: "Organization Management",
              prefix: "O",
              display: checkPermission(
                this.permissions,
                "Admin:AccountManage",
                "Organizations"
              ),
              link: "/accounts/organizations",
            },
            {
              title: "Department Management",
              prefix: "D",
              display: checkPermission(
                this.permissions,
                "Admin:AccountManage",
                "Departments"
              ),
              link: "/accounts/departments",
            },
            {
              title: "Event Organizer Management",
              prefix: "E",
              display: false,
              link: "/accounts/organizations",
            },
            {
              title: "Member Management",
              prefix: "M",
              display: checkPermission(
                this.permissions,
                "Admin:AccountManage",
                "Members"
              ),
              link: "/accounts/members",
            },
            {
              title: "Premember Management",
              prefix: "PM",
              display: checkPermission(
                this.permissions,
                "Admin:AccountManage",
                "Premembers"
              ),
              link: "/accounts/pre-members",
            },
          ],
        },
        {
          material: true, // materal icon
          action: "storefront", // icon
          active: groupName === "Store Management", // selected
          title: "Store Management",
          display: checkPermission(this.permissions, "Admin:StoreManage", null),
          items: [
            {
              title: "Store Categories",
              prefix: "C",
              display: checkPermission(
                this.permissions,
                "Admin:StoreManage",
                "StoreCategories"
              ),
              link: "/stores/categories",
            },
            {
              title: "Store List",
              prefix: "S",
              display: checkPermission(
                this.permissions,
                "Admin:StoreManage",
                "Stores"
              ),
              link: "/stores/list",
            },
          ],
        },
        {
          material: false, // materal icon
          action: "fa-coins", // icon
          active: groupName === "Points", // selected
          title: "Points",
          display: checkPermission(this.permissions, "Admin:PointManage", null),
          items: [
            {
              title: "Point Dashboard",
              prefix: "D",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "PointDashboard"
              ),
              link: "/points/dashboard",
            },
            {
              title: "Point Configuration",
              prefix: "PC",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "PointSettings"
              ),
              link: "/points/point-configuration",
            },
            {
              title: "Period Management",
              prefix: "PM",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "PointPeriods"
              ),
              link: "/points/period-management",
            },
            {
              title: "Annual Budget",
              prefix: "AB",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "AnnualBudgets"
              ),
              link: "/points/annual-budget",
            },
            {
              title: "Department Budget",
              prefix: "DB",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "DepartmentBudgets"
              ),
              link: "/points/department-budget",
            },
            {
              title: "Campaign Budget",
              prefix: "CB",
              display: checkPermission(
                this.permissions,
                "Admin:PointManage",
                "CampaignBudgets"
              ),
              link: "/points/campaign-budget",
            },
          ],
        },
        // {
        //   material: true, // materal icon
        //   action: "redeem", // icon
        //   active: false, // selected
        //   title: "Tickets",
        //   display: true,
        //   items: [
        //     {
        //       title: "Ticket Dashboard",
        //       prefix: "D",
        //       display: true,
        //       link: "/tickets/dashboard",
        //     },
        //     {
        //       title: "Ticket Management",
        //       prefix: "TM",
        //       display: true,
        //       link: "/tickets/management",
        //     },
        //   ],
        // },
        {
          material: true, // materal icon
          action: "local_activity", // icon
          active: groupName === "Campaigns", // selected
          title: "Campaigns",
          display: checkPermission(
            this.permissions,
            "Admin:CampaignManage",
            null
          ),
          items: [
            {
              title: "Campaign List",
              prefix: "L",
              display: checkPermission(
                this.permissions,
                "Admin:CampaignManage",
                "Campaigns"
              ),
              link: "/campaigns/list",
            },
          ],
        },
        {
          material: true, // materal icon
          action: "price_check", // icon
          active: groupName === "Accounting Management", // selected
          title: "Accounting Management",
          display: checkPermission(
            this.permissions,
            "Admin:AccountingManage",
            null
          ),
          items: [
            {
              title: "Withdrawal List",
              prefix: "L",
              display: checkPermission(
                this.permissions,
                "Admin:AccountingManage",
                "Withdrawals"
              ),
              link: "/withdraw/list",
            },
            {
              title: "ACH Records",
              prefix: "R",
              display: checkPermission(
                this.permissions,
                "Admin:AccountingManage",
                "Ach"
              ),
              link: "/withdraw/ach-records",
            },
          ],
        },
        {
          material: true, // materal icon
          action: "campaign", // icon
          active: false, // selected
          title: "Landing Page Management",
          display: checkPermission(
            this.permissions,
            "Admin:ArticleManage",
            null
          ),
          items: [
            {
              title: "Article Category Management",
              prefix: "C",
              display: checkPermission(
                this.permissions,
                "Admin:ArticleManage",
                "ArticleCategories"
              ),
              link: "/landing/article-categories",
            },
            {
              title: "Article Management",
              prefix: "A",
              display: checkPermission(
                this.permissions,
                "Admin:ArticleManage",
                "Articles"
              ),
              link: "/landing/articles",
            },
            {
              title: "Banner Management",
              prefix: "B",
              display: checkPermission(
                this.permissions,
                "Admin:ArticleManage",
                "Banners"
              ),
              link: "/landing/banners",
            },
          ],
        },
        {
          material: false, // materal icon
          action: "fa-gift", // icon
          active: groupName === "Donation Management", // selected
          title: "Donation Management",
          // display: true,
          display: checkPermission(
            this.permissions,
            "Admin:DonateManage",
            null
          ),
          items: [
            {
              title: "Invoice Donation Config",
              prefix: "I",
              // display: true,
              display: checkPermission(
                this.permissions,
                "Admin:DonateManage",
                "DonateInvoices"
              ),
              link: "/donate/invoice-config",
            },
            {
              title: "Invoice Donation Dashboard",
              prefix: "D",
              // display: true,
              display: checkPermission(
                this.permissions,
                "Admin:DonateManage",
                "DonateInvoices"
              ),
              link: "/donate/invoice-dashboard",
            },
          ],
        },
        {
          material: false, // materal icon
          action: "fa-chart-bar", // icon
          active: groupName === "Marketing Management", // selected
          title: "Marketing Management",
          // display: true,
          display: checkPermission(
            this.permissions,
            "Admin:MarketingManage",
            null
          ),
          items: [
            {
              title: "Data Query",
              prefix: "Q",
              display: checkPermission(
                this.permissions,
                "Admin:MarketingManage",
                "DataQuery"
              ),
              link: "/marketing/data-query",
            },
          ],
        },
      ];
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }

      // 小尺寸畫面時，sidebar關閉後才能再打開
      if (this.$vuetify.breakpoint.mobile) {
        this.$emit("drawer-toggle", false);
      }
    },
    onClickTab($event, title) {
      console.log($event, title);
      switch (title) {
        case "Logout":
          this.logout();
          break;
      }
    },
    logout() {
      console.log("start logout");
      const qcsso = window.qcsso;
      let redirectUrl = window.location.protocol + "//" + window.location.host;

      // console.log(redirectUrl);
      qcsso
        .logout({ logoutRedirectUri: redirectUrl })
        .then(() => {
          console.log("logout succss");
          // window.location = "https://oidc-staging.tycard.app/session/end";
        })
        .catch((e) => {
          console.error("logout error", e);
        });
    },
  },
};
</script>
