import Vue from "vue";
import VueRouter from "vue-router";
import DDCBasicLayout from "../views/Layout/DDCBasicLayout.vue";

// Points
const PointDashboard = () => import("../views/Points/Dashboard.vue");
const PointSettings = () => import("../views/Points/PointSettings.vue");
const PointPeriods = () => import("../views/Points/PointPeriods.vue");
const AnnualBudgets = () => import("../views/Points/AnnualBudgets.vue");
const DepartmentBudgets = () => import("../views/Points/DepartmentBudgets.vue");
const CampaignBudgets = () => import("../views/Points/CampaignBudgets.vue");

const StoreList = () => import("../views/Stores/StoreList.vue");
const StoreCategories = () => import("../views/Stores/StoreCategories.vue");

const Users = () => import("../views/Accounts/Users");
const Departments = () => import("../views/Accounts/Departments");
const Organizations = () => import("../views/Accounts/Organizations");
const Members = () => import("../views/Members/Members");
const PreMembers = () => import("../views/Members/PreMembers");

const WithdrawalList = () => import("../views/Withdrawal/WithdrawalList.vue");

const CampaignList = () => import("../views/Campaigns/CampaignList.vue");
const Banners = () => import("../views/Landing/Banners.vue");
const Articles = () => import("../views/Landing/Articles.vue");
const ArticleCategories = () =>
  import("../views/Landing/ArticleCategories.vue");
const DonateInvoice = () => import("../views/Donate/DonateInvoice.vue");
const DonateInvoiceDashboard = () => import("../views/Donate/Dashboard.vue");

const DataQuery = () => import("../views/Marketing/DataQuery.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Points",
    redirect: "points/dashboard",
    component: DDCBasicLayout,
    children: [
      {
        path: "points/dashboard",
        name: "Point Dashboard",
        component: PointDashboard,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
      {
        path: "points/point-configuration",
        name: "Point Configuration",
        component: PointSettings,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
      {
        path: "points/period-management",
        name: "Period Management",
        component: PointPeriods,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
      {
        path: "points/annual-budget",
        name: "Annual Budget",
        component: AnnualBudgets,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
      {
        path: "points/department-budget",
        name: "Department Budget",
        component: DepartmentBudgets,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
      {
        path: "points/campaign-budget",
        name: "Campaign Budget",
        component: CampaignBudgets,
        meta: {
          groupName: "Points",
          authName: "points",
        },
      },
    ],
  },
  {
    path: "/stores",
    name: "Stores",
    component: DDCBasicLayout,
    children: [
      {
        path: "list",
        name: "Store List",
        component: StoreList,
        meta: {
          groupName: "Store Management",
          authName: "stores",
        },
      },
      {
        path: "categories",
        name: "Store Categories",
        component: StoreCategories,
        meta: {
          groupName: "Store Management",
          authName: "stores",
        },
      },
    ],
  },
  {
    path: "/accounts",
    name: "Account Management",
    component: DDCBasicLayout,
    children: [
      {
        path: "users",
        name: "User Management",
        component: Users,
        meta: {
          groupName: "Account Management",
          authName: "account",
        },
      },
      {
        path: "organizations",
        name: "Organization Management",
        component: Organizations,
        meta: {
          groupName: "Account Management",
          authName: "account",
        },
      },
      {
        path: "departments",
        name: "Department Management",
        component: Departments,
        meta: {
          groupName: "Account Management",
          authName: "account",
        },
      },
      {
        path: "members",
        name: "Member Management",
        component: Members,
        meta: {
          groupName: "Account Management",
          authName: "account",
        },
      },
      {
        path: "pre-members",
        name: "Premember Management",
        component: PreMembers,
        meta: {
          groupName: "Account Management",
          authName: "account",
        },
      },
    ],
  },
  // {
  //   path: "/members",
  //   name: "Members",
  //   component: DDCBasicLayout,
  //   children: [
  //     {
  //       path: "profile",
  //       name: "User Profile",
  //       component: PointDashboard,
  //       meta: {
  //         groupName: "Members",
  //         authName: "account",
  //       },
  //     },
  //     {
  //       path: "settings",
  //       name: "Settings",
  //       component: PointDashboard,
  //       meta: {
  //         groupName: "Members",
  //         authName: "account",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: DDCBasicLayout,
    children: [
      {
        path: "list",
        name: "Withdrawal List",
        component: WithdrawalList,
        meta: {
          groupName: "Accounting Management",
          authName: "accounting",
        },
      },
      {
        path: "ach-records",
        name: "ACH Records",
        component: StoreCategories,
        meta: {
          groupName: "Accounting Management",
          authName: "accounting",
        },
      },
    ],
  },
  {
    path: "/campaigns",
    name: "Campaign",
    component: DDCBasicLayout,
    children: [
      {
        path: "list",
        name: "Campaign List",
        component: CampaignList,
        meta: {
          groupName: "Campaigns",
          authName: "campaigns",
        },
      },
    ],
  },
  {
    path: "/landing",
    name: "Landing Page",
    component: DDCBasicLayout,
    children: [
      {
        path: "article-categories",
        name: "Article Category Management",
        component: ArticleCategories,
        meta: {
          groupName: "Landing Page Management",
          authName: "articles",
        },
      },
      {
        path: "articles",
        name: "Article Management",
        component: Articles,
        meta: {
          groupName: "Landing Page Management",
          authName: "articles",
        },
      },
      {
        path: "banners",
        name: "Banner Management",
        component: Banners,
        meta: {
          groupName: "Landing Page Management",
          authName: "articles",
        },
      },
    ],
  },
  {
    path: "/donate",
    name: "Donate Page",
    component: DDCBasicLayout,
    children: [
      {
        path: "invoice-config",
        name: "Invoice Donation Config",
        component: DonateInvoice,
        meta: {
          groupName: "Donation Management",
          authName: "donate",
        },
      },
      {
        path: "invoice-dashboard",
        name: "Invoice Donation Dashboard",
        component: DonateInvoiceDashboard,
        meta: {
          groupName: "Donation Management",
          authName: "donate",
        },
      },
    ],
  },
  {
    path: "/marketing",
    name: "Marketing Page",
    component: DDCBasicLayout,
    children: [
      {
        path: "data-query",
        name: "Data Query",
        component: DataQuery,
        meta: {
          groupName: "Marketing Management",
          authName: "marketing",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL ? process.env.BASE_URL : "/",
  routes,
});

export default router;
