<template>
  <div v-if="false">
    {{ ssoUser }}
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getUserPermission } from "src/apis/identitiesCore";
export default {
  props: {},
  data() {
    return {
      appId: process.env.VUE_APP_SSO_APP_ID,
      ssoUrl: process.env.VUE_APP_SSO_URL,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    ...mapState({
      ssoUser: (state) => state.ssoUser,
    }),
  },
  mounted() {
    console.log(
      "ssourl:" + this.ssoUrl,
      this.appId,
      process.env.VUE_APP_I18N_LOCALE
    );

    if (!window.qcsso) {
      const script = document.createElement("script");
      script.onload = this.onScriptLoaded;
      script.type = "text/javascript";
      script.src = this.ssoUrl;
      document.head.appendChild(script);
    } else {
      this.onScriptLoaded();
    }
  },
  methods: {
    onScriptLoaded(event = null) {
      if (event) {
        console.log("[login] qcsso was added");
      } else {
        console.log("[login] qcsso already existed");
      }
      this.init();
    },
    init() {
      const qcsso = window.qcsso;
      const redirectUrl = window.location;
      // const isLoggedIn = qcsso.isLoggedIn();
      // console.log("isLoggedIn: " + isLoggedIn);
      qcsso
        .init({
          appId: this.appId,
        })
        .then(() => {
          if (!qcsso.isLoggedIn()) {
            qcsso.login({ redirectUrl });
          } else {
            this.getUserProfile();
          }
        })
        .catch((e) => {
          console.log("SSO init exception:", e);
          self.$emit("error", this.$i18n.t("message.failedToLogin"));
        });
      // if (isLoggedIn) {
      //   this.getUserProfile();
      // } else {
      //   // 自動登入
      //   console.log("try auto login");
      //   this.login();
      // }
    },
    // login() {
    //   console.log("start login");
    //   const qcsso = window.qcsso;
    //   const redirectUrl = window.location;
    //   console.log("login redirect:" + redirectUrl);
    //   qcsso
    //     .init({
    //       appId: this.appId,
    //     })
    //     .then(() => {
    //       if (!qcsso.isLoggedIn()) {
    //         qcsso.login({ redirectUrl });
    //       } else {
    //         this.getUserProfile();
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("SSO init exception:", e);
    //       self.$emit("error", this.$i18n.t("message.failedToLogin"));
    //     });
    // },
    getUserProfile() {
      console.log("[login] start get user profilie");
      try {
        const self = this;
        const qcsso = window.qcsso;
        const token = qcsso.getAccessToken();
        qcsso
          .getProfile()
          .then(function (user) {
            console.log("done get user profile", user);
            user.token = token;
            self.$store.commit("setSSOUser", user);
            // self.$store.commit("setIsLoggedIn", true);

            // GET user permisssions
            console.log("merchant ID:" + user.user._json?.groups?.merchant);
            const merchantId = user.user._json?.groups?.merchant;
            const userId = user.userId;
            let permissions = [];
            if (!merchantId) {
              console.log("No merchant ID.");
              self.$store.commit("setUserPermission", permissions);
              // 避免login後判斷權限，但權限未取得
              self.$store.commit("setIsLoggedIn", true);
              return;
            }
            getUserPermission(merchantId, userId)
              .then((res) => {
                console.log("done get user permission");
                // console.log("done get user permission:" + JSON.stringify(res));
                permissions = res.data;
              })
              .catch((e) => {
                console.log("get user permission error:", e);
                // permissions = [
                //   {
                //     service: "point-manage",
                //     actions: "*",
                //     effect: "Allow",
                //   },
                //   {
                //     service: "campaign-manage",
                //     actions: "*",
                //     effect: "Allow",
                //   },
                //   {
                //     service: "article-manage",
                //     actions: "*",
                //     effect: "Allow",
                //   },
                //   {
                //     service: "accounting-manage",
                //     actions: ["ViewWithdrawals", "AuditWithdrawals"],
                //     effect: "Allow",
                //   },
                //   {
                //     service: "account-manage",
                //     actions: "*",
                //     effect: "Allow",
                //   },
                // ];
              })
              .finally(() => {
                console.log("permission:", permissions);
                self.$store.commit("setUserPermission", permissions);
                // 避免login後判斷權限，但權限未取得
                self.$store.commit("setIsLoggedIn", true);
              });

            // 取得profile後，檢查用戶是否註冊會員，重導至不同頁面
            // {"userId":"KxewXRaZrPPRryQkbOgp","scope":"*","uuid":"d33c6808-ed2d-5c08-abbd-21f9c93c62b7"}
            // uuid --> 平台上此身份unique的id (userId 有可能重複，例如某兩種oauth service的id可能會撞到彼此，但uuid一定不重複)
            // userId --> 第三方真正的userId (可能會用於第三方的API，例如LINE的uid拿來推播之類的)
            // alert('登入成功');
          })
          .catch(function (e) {
            console.log(e);
            self.isLoading = false;
            self.logout();
          })
          .finally(() => {});
      } catch (e) {
        this.isLoading = false;
      }
    },
    logout() {
      console.log("start logout");
      const qcsso = window.qcsso;
      let redirectUrl = window.location.protocol + "//" + window.location.host;
      qcsso
        .logout({ logoutRedirectUri: redirectUrl })
        .then(() => {
          console.log("logout succss");
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
