<template>
  <v-app>
    <login />
    <ddc-drawer
      v-if="isLoggedIn"
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
      @drawer-toggle="drawer = $event"
    >
    </ddc-drawer>
    <v-main v-if="isLoggedIn">
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <ddc-app-bar
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></ddc-app-bar>

      <fade-transition
        v-if="isAuthed"
        :duration="200"
        origin="center top"
        mode="out-in"
      >
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <v-container v-else fluid class="px-6 py-6">
        <v-card class="mb-6 card-shadow border-radius-xl py-4">
          <v-card-text>
            <p class="text-h5 text--primary">您尚未被授權檢視此頁面</p>
          </v-card-text>
        </v-card>
      </v-container>
      <ddc-content-footer v-if="!$route.meta.hideFooter"></ddc-content-footer>
      <!-- <v-btn
        :ripple="false"
        icon
        rounded
        color="#fff"
        width="52px"
        height="52px"
        class="
          fixed-plugin-button
          position-fixed
          btn-light
          bg-white
          text-dark
          z-index-9999
        "
        @click="showSettingsDrawer = true"
      >
        <v-icon size="20">fa fa-cog py-2</v-icon>
      </v-btn> -->

      <!-- <settings-drawer
        :showSettingsDrawer="showSettingsDrawer"
        @toggleSettingsDrawer="toggleSettingsDrawer"
        @updateSidebarColor="updateSidebarColor"
        @updateSidebarTheme="updateSidebarTheme"
        @toggleNavbarPosition="toggleNavbarPosition"
      >
      </settings-drawer> -->
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import variables from "src/util/variables";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import DdcDrawer from "@/components/DdcDrawer.vue";
import DdcAppBar from "@/components/DdcAppBar.vue";
import DdcContentFooter from "@/components/DdcFooter.vue";
import Login from "@/components/Login.vue";
import { mapState } from "vuex";
import { checkPermission } from "src/util/utils";

// import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";

export default {
  components: {
    DdcContentFooter,
    FadeTransition,
    DdcDrawer,
    DdcAppBar,
    Login,
    // SettingsDrawer,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "success", // sidebar active color
      sidebarTheme: "dark",
      navbarFixed: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    isAuthed() {
      if (!this.$route.meta.authName) {
        // 沒設定authName，視為不檢查
        return true;
      }
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          variables.authServices[this.$route.meta.authName],
          null
        );
      }
      return false;
    },
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
